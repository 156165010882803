import { useQuery } from 'react-query'
import { UserAPI } from '../../apis'

type propsType = {
  orgId: number | null
}

const useOrganizationUsers = (props: propsType) => {
  const { data, isLoading, isError, error } = useQuery(
    ['organization', 'user', 'list', props.orgId],
    () => UserAPI.getAllUsers(props.orgId, {}),
    {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  )

  return { count: data?.length || 0, data, isLoading, isError, error }
}

export default useOrganizationUsers
