import { useQuery } from 'react-query'
import { OrganizationAPI } from '../../apis'

type propsType = {
  orgId: number | null
}

const useOrganization = (props: propsType) => {
  const { data, isLoading, isError, error } = useQuery(
    ['organization', 'get', props.orgId],
    () => OrganizationAPI.getOrganization(props.orgId),
    {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  )

  return { data, isLoading, isError, error }
}

export default useOrganization
