import React, { useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Input, Button, Form } from 'antd'
import { AuthenticationAPI } from '../../apis'

// eslint-disable-next-line no-shadow
export enum AuthenticationType {
  PASSWORD = 'password',
  IDP_SSO = 'sso',
}

type AuthenticationTypeFormProps = {
  setAuthType(
    authType: string,
    username: string,
    connection: string | null
  ): void
}

const AuthenticationTypeForm = ({
  setAuthType,
}: AuthenticationTypeFormProps) => {
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = () => {
    setLoading(true)
    AuthenticationAPI.getSSOConnectionName(username)
      .then(res => {
        const { oidcConnectionName, samlConnectionName } = res
        if (oidcConnectionName || samlConnectionName) {
          setAuthType(
            AuthenticationType.IDP_SSO,
            username,
            oidcConnectionName || samlConnectionName
          )
        } else {
          setAuthType(AuthenticationType.PASSWORD, username, null)
        }
      })
      .catch(err => {
        setLoading(false)
        setAuthType(AuthenticationType.PASSWORD, username, null)
      })
  }

  const handleUsernameChange = (event: any) => {
    setUsername(event.target.value)
  }

  return (
    <Form onFinish={handleFormSubmit}>
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Please input your email' }]}
      >
        <Input
          size="large"
          value={username}
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email Address"
          type="email"
          onChange={handleUsernameChange}
          autoFocus
        />
      </Form.Item>
      <Form.Item>
        <Button
          size="large"
          disabled={loading}
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AuthenticationTypeForm
