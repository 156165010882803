import { AxiosResponse } from 'axios'
import { makeUMRequest } from '../utils/request'
import { getFeatures, USER_SPLIT_FEATURE_NAMES } from '../apis/feature.api'
import {
  IAccessTokenResponse,
  IAuth0LoginResponse,
  ILoginResponse,
  IUMTokenResponse,
  IUserInfoResponse,
} from '../utils/types'

type LoginRequest = {
  user: string
  password: string
}

type Auth0LoginRequest = {
  authorizationCode: string
  idToken: string
}

type UMTokenRequest = {
  orgId: number
  productName: string
}

type AccessTokenRequest = {
  // eslint-disable-next-line camelcase
  authorization_code: string
  state: string
  redirectUrl: string
}

type RefreshAccessTokenRequest = {
  // eslint-disable-next-line camelcase
  refresh_token: string | null
}

class AuthenticationAPI {
  static async login(data: LoginRequest): Promise<ILoginResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: '/auth/login',
      data,
    })
    return res.data
  }

  static async authOLogin(
    data: Auth0LoginRequest
  ): Promise<IAuth0LoginResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: '/auth/auth0_login',
      data,
    })
    return res.data
  }

  static async getUserInfo(): Promise<IUserInfoResponse> {
    const { data: userInfo2 }: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: '/auth/userinfo',
    })

    localStorage.setItem('csrfToken', userInfo2.csrfToken)
    const response = await getFeatures({
      userFeatures: [USER_SPLIT_FEATURE_NAMES.IAM_org_switcher],
    })

    if (
      response.userFeatures[USER_SPLIT_FEATURE_NAMES.IAM_org_switcher] === 'on'
    ) {
      return AuthenticationAPI.getUserInfoV2()
    } else {
      const res: AxiosResponse = await makeUMRequest({
        method: 'GET',
        url: '/auth/userinfo',
      })
      return res.data
    }
  }

  static async getUserInfoV2(): Promise<IUserInfoResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: '/auth/v2/userinfo',
    })
    return res.data
  }

  static async getToken(data: UMTokenRequest): Promise<IUMTokenResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: '/auth/token',
      data,
    })
    return res.data
  }

  static async getAccessToken(
    params: AccessTokenRequest
  ): Promise<IAccessTokenResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: '/auth/token/oidctoken',
      params,
    })
    return res.data
  }

  static async refreshAccessToken(
    params: RefreshAccessTokenRequest
  ): Promise<IAccessTokenResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: '/auth/token/refreshoidctoken',
      params,
    })
    return res.data
  }

  static async getSSOConnectionName(email: string): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: '/profile/ssoconnectionname',
      params: {
        email,
      },
    })
    return res.data
  }

  static async requestPasswordReset(email: string): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `auth/request-password-reset`,
      data: {
        userName: email,
        productName: 'selfServiceAdmin',
      },
    })
    return res.data
  }

  static async requestNewPassword(passwordResetToken: string): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `auth/reset`,
      data: {
        accountResetToken: passwordResetToken,
      },
    })
    return res.data
  }

  static async logout(): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: '/auth/logout',
    })
    return res.data
  }
}

export default AuthenticationAPI
