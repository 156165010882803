import { ILicenseResponse } from '../../utils/types'
import useOrganizationLicenses from './organization-licenses'

type propsType = {
  orgId: number
  licenseId: number
}

const useOrganizationLicense = (props: propsType) => {
  const { data, isLoading, isError, error } = useOrganizationLicenses({
    orgId: props.orgId,
  })
  const license =
    data &&
    data.filter((obj: ILicenseResponse) => {
      return obj.id === props.licenseId
    })[0]

  return { data: license, isLoading, isError, error }
}

export default useOrganizationLicense
