import AuthenticationAPI from '../apis/authentication.api'
import {
  IAccessTokenResponse,
  IAuth0LoginResponse,
  ILoginResponse,
  IUMTokenResponse,
  IUserInfoResponse,
} from './types'

class Authentication {
  static async login(user: string, password: string): Promise<ILoginResponse> {
    const res: ILoginResponse = await AuthenticationAPI.login({
      user,
      password,
    })
    this.clearUserSessionInfoStorage()
    localStorage.setItem('isIDPLogin', 'false')
    localStorage.setItem('csrfToken', res.token)
    return res
  }

  static async auth0Login(
    authorizationCode: string,
    idToken: string
  ): Promise<IAuth0LoginResponse> {
    const res: IAuth0LoginResponse = await AuthenticationAPI.authOLogin({
      authorizationCode,
      idToken,
    })
    this.clearUserSessionInfoStorage()
    localStorage.setItem('isIDPLoginViaSAML', 'true')
    localStorage.setItem('csrfToken', res.token)
    return res
  }

  static async getUMToken() {
    const userInfo: IUserInfoResponse = await AuthenticationAPI.getUserInfo()
    localStorage.setItem('csrfToken', userInfo.csrfToken)
    if (userInfo.activeOrganization.idpUrl) {
      localStorage.setItem('idpUrl', userInfo.activeOrganization.idpUrl)
    }
    const orgId = userInfo.activeOrganization.id
    const token: IUMTokenResponse = await AuthenticationAPI.getToken({
      orgId,
      productName: 'Reactor',
    })
    localStorage.setItem('UMToken', token.token)
    return { userInfo, token }
  }

  static async oidcLogin(
    authorizationCode: string,
    state: string,
    redirectUrl: string
  ): Promise<IAccessTokenResponse> {
    const token: IAccessTokenResponse = await AuthenticationAPI.getAccessToken({
      authorization_code: authorizationCode,
      state,
      redirectUrl,
    })
    this.clearUserSessionInfoStorage()
    localStorage.setItem('isIDPLogin', 'true')
    localStorage.setItem('csrfToken', token.token)
    localStorage.setItem('accessToken', token.accessToken)
    localStorage.setItem('refreshToken', token.refreshToken)
    return token
  }

  static async refreshOIDCAccessToken(
    refreshToken: string | null
  ): Promise<IAccessTokenResponse> {
    const token: IAccessTokenResponse =
      await AuthenticationAPI.refreshAccessToken({
        refresh_token: refreshToken,
      })
    localStorage.setItem('accessToken', token.accessToken)
    localStorage.setItem('refreshToken', token.refreshToken)
    return token
  }

  static logout(): void {
    this.clearUserSessionInfoStorage()
    window.location.href = process.env.REACT_APP_LOGIN_URL || '/login'
  }

  private static clearUserSessionInfoStorage() {
    localStorage.removeItem('isIDPLogin')
    localStorage.removeItem('isIDPLoginViaSAML')
    localStorage.removeItem('idpUrl')
    localStorage.removeItem('csrfToken')
    localStorage.removeItem('UMToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

export default Authentication
