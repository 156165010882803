import { useQuery } from 'react-query'
import { UserAPI } from '../../apis'

type propsType = {
  orgId: number
  userId: number
}

const useOrganizationUser = (props: propsType) => {
  const { data, isLoading, isError, error } = useQuery(
    ['organization', 'user', 'get', props.orgId, props.userId],
    () => UserAPI.getUser(props.orgId, props.userId),
    {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  )

  return { data, isLoading, isError, error }
}

export default useOrganizationUser
