import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useAuth } from './hooks'
import Loader from './Loader'
import Organization from './Organization'
import Unauthorized from './Unauthorized'

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const { isAuthed, isFirstRender, isOrgAdmin } = useAuth()
  return (
    <Route
      {...rest}
      render={props => {
        if (isFirstRender) {
          return <Loader container />
        }
        if (!isAuthed) {
          window.location.href = process.env.REACT_APP_LOGIN_URL || '/login'
        }
        if (!isOrgAdmin) {
          return <Unauthorized />
        }
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
