import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import {
  Link,
  Redirect,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom'
// import queryString from 'query-string'
/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { LockOutlined, UserOutlined, DownOutlined } from '@ant-design/icons'
import { Input, Button, Layout, Form } from 'antd'
import logo from '../../img/chainalysis-logo.svg'
import Loader from '../Loader'
import { ErrorMessage } from '../styled/text'
import { LoginPagePattern } from '../styled'
import { useAuth } from '../hooks'
import auth0OidcAuthentication from '../../utils/auth0-oidc-authentication'
import Authentication from '../../utils/authentication'
import { parseLocalStorageItem } from '../../utils'
import AuthenticationTypeForm, {
  AuthenticationType,
} from './AuthenticationTypeForm'

const { Content } = Layout

interface ErrorMessages {
  [key: string]: string
}

const errorMessages: ErrorMessages = {
  default: 'Something went wrong. Please try to login again',
  server: 'Admin Portal is currently unavailable',
  badCredentials: 'Incorrect username or password',
  accountLocked: 'Account is locked. Please wait 10 minutes',
  organizationMembershipExpired: 'Organization membership has expired',
  invalidOIDCAuthorizationCode:
    'Invalid authorization code. Please try to login again',
}

type OidcCallbackParams = {
  code?: string
  state?: string
  /* eslint-disable camelcase */
  id_token?: string
  error?: string
  /* eslint-disable camelcase */
  error_description?: string
}

type LocationType = { pathname: string }

// eslint-disable-next-line @typescript-eslint/ban-types
type LoginProps = RouteComponentProps<{}, {}, LocationType>

// eslint-disable-next-line @typescript-eslint/ban-types
const Login = (routeProps: RouteComponentProps<{}>) => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [redirectToReferrer, setRedirectToReferrer] = useState<boolean>(false)
  const [isBtnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [authenticationType, setAuthenticationType] =
    useState<AuthenticationType | null>(null)
  const { state }: any = useLocation()
  const [referrerPath, setReferrerPath] = useState({
    pathname: '/organization',
  })
  const { isAuthed, checkAuth } = useAuth()

  useEffect(() => {
    if (state) {
      // store referrer on local storage to redirect to desired path after OIDC login
      setReferrerPath(state.from)
      localStorage.setItem('referrer', JSON.stringify(state))
    } else if (localStorage.getItem('referrer')) {
      const referrer = parseLocalStorageItem('referrer')
      setReferrerPath(referrer.from)
      // delete referrer once used
      localStorage.removeItem('referrer')
    }
  }, [state])

  useEffect((): void => {
    if (isAuthed) {
      setRedirectToReferrer(true)
    } else {
      // retrieve OIDC callback params
      const queryParams: OidcCallbackParams = routeProps.location.search
        ? queryString.parse(routeProps.location.search)
        : queryString.parse(routeProps.location.hash)

      // Auth0 OIDC/SAML login error
      if (queryParams.error && queryParams.error_description) {
        setError(queryParams.error_description)
      }

      // Auth0 SAML redirection login
      if (queryParams.code && queryParams.id_token) {
        Authentication.auth0Login(queryParams.code, queryParams.id_token)
          .then(() => {
            return checkAuth()
          })
          .then(() => {
            setRedirectToReferrer(true)
          })
          .catch((err: any) => {
            handleLoginError(err)
          })
        return
      }

      // Auth0 OIDC redirection login
      if (queryParams.code && queryParams.state) {
        const redirectUrl =
          window.location.origin + process.env.REACT_APP_OIDC_REDIRECT_PATH
        Authentication.oidcLogin(
          queryParams.code,
          queryParams.state,
          redirectUrl
        )
          .then(() => {
            return checkAuth()
          })
          .then(() => {
            setRedirectToReferrer(true)
          })
          .catch((err: any) => {
            handleLoginError(err)
          })
        return
      }
      setRedirectToReferrer(true)
    }
  }, [isAuthed])

  const handleLoginError = (err: {
    response: { data: { message: string; errorCode: string }; status: number }
  }) => {
    const { response } = err
    if (response?.data.errorCode === 'BadCredentials') {
      setError(errorMessages.badCredentials)
    } else if (response?.data.errorCode === 'AccountLocked') {
      setError(errorMessages.accountLocked)
    } else if (response?.data.errorCode === 'OrganizationMemberShipExpired') {
      setError(errorMessages.organizationMembershipExpired)
    } else if (response?.data.errorCode === 'AuthorizationCodeGrantError') {
      setError(errorMessages.invalidOIDCAuthorizationCode)
    } else if (response) {
      setError(errorMessages.default)
    } else {
      setError(errorMessages.server)
    }
    setLoading(false)
    setBtnDisabled(false)
  }

  const handleLogin = () => {
    setBtnDisabled(true)
    Authentication.login(username, password)
      .then(() => {
        return checkAuth()
      })
      .then(() => {
        setRedirectToReferrer(true)
      })
      .catch(
        (err: {
          response: {
            data: { message: string; errorCode: string }
            status: number
          }
        }) => {
          handleLoginError(err)
        }
      )
  }

  const setAuthType = (
    authType: AuthenticationType,
    email: string,
    connectionName: string | null
  ) => {
    if (authType === AuthenticationType.IDP_SSO && connectionName) {
      auth0OidcAuthentication.login(connectionName)
    }
    setAuthenticationType(authType)
    setUsername(email)
  }

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value)
  }

  const resetAuthenticationType = () => {
    setPassword('')
    setError(null)
    setAuthenticationType(null)
  }

  return redirectToReferrer ? (
    <Redirect to={referrerPath} />
  ) : loading ? (
    <Loader container />
  ) : (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Content
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          height: '100%',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <LoginPagePattern />
        <div
          css={{
            flex: '1 1 61.8%',
            padding: '4rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <img src={logo} alt="Chainalysis" />
          </div>
          <div>
            <ErrorMessage>{error || <span>&nbsp;</span>}</ErrorMessage>

            {authenticationType === AuthenticationType.PASSWORD ? (
              <Form onFinish={handleLogin}>
                <Form.Item name="username">
                  {/* username input is kept hidden to support password auto-complete */}
                  <Input
                    style={{ display: 'none' }}
                    type="email"
                    value={username}
                  />
                  <div
                    role="presentation"
                    style={{
                      padding: '5px 15px 5px 15px',
                      border: '1px solid #dadce0',
                      borderRadius: '15px',
                      cursor: 'pointer',
                      display: 'inline-block',
                    }}
                    onClick={resetAuthenticationType}
                  >
                    <UserOutlined style={{ paddingRight: '8px' }} />
                    <span style={{ paddingRight: '8px' }}>{username}</span>
                    <DownOutlined />
                  </div>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Please input your password' },
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={
                      <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="Password"
                    onChange={handlePasswordChange}
                    autoFocus
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    size="large"
                    disabled={isBtnDisabled}
                    loading={isBtnDisabled}
                    type="primary"
                    htmlType="submit"
                  >
                    Sign in
                  </Button>
                </Form.Item>
                <Link to="/forgot-password">Forgot your password?</Link>
              </Form>
            ) : (
              <AuthenticationTypeForm setAuthType={setAuthType} />
            )}
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Login
