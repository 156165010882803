import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { BehaviorSubject } from 'rxjs'
import { filter, switchMap, take } from 'rxjs/operators'
import { getFeatures, USER_SPLIT_FEATURE_NAMES } from '../apis/feature.api'

// rxjs subjects to observe token renewal status
const accessTokenRenewalInProgressSubject = new BehaviorSubject(false)
const UMTokenRenewalInProgressSubject = new BehaviorSubject(false)
// maximum retry count when request failed with 403 status
const MAX_REQUEST_RETRIES = 5

const umClient = axios.create({
  baseURL: process.env.REACT_APP_UMBE_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
})

const makeUMRequest = (options: AxiosRequestConfig): AxiosPromise => {
  umClient.defaults.headers['x-csrf-token'] = localStorage.getItem('csrfToken')
  return umClient(options)
}

/**
 * Extract bearer token from the request Authorization header
 * @param req
 */
const parseBearerToken = (req: any): string | null => {
  const auth = req.headers ? req.headers.Authorization || null : null
  if (!auth) {
    return null
  }

  const parts = auth.split(' ')
  // Malformed header
  if (parts.length < 2) {
    return null
  }

  const schema = parts.shift().toLowerCase()
  const token = parts.join(' ')
  if (schema !== 'bearer') {
    return null
  }

  return token
}

/**
 * Extract UM token from the request header
 * @param req
 */
const parseUMToken = (req: any): string | null => {
  const token = req.headers ? req.headers.Token || null : null
  return token
}

const getUMToken = async () => {
  const { data: userInfo2 }: AxiosResponse = await makeUMRequest({
    method: 'GET',
    url: '/auth/userinfo',
  })

  localStorage.setItem('csrfToken', userInfo2.csrfToken)

  let res: AxiosResponse
  const response = await getFeatures({
    userFeatures: [USER_SPLIT_FEATURE_NAMES.IAM_org_switcher],
  })

  if (
    response.userFeatures[USER_SPLIT_FEATURE_NAMES.IAM_org_switcher] === 'on'
  ) {
    res = await makeUMRequest({
      method: 'GET',
      url: '/auth/v2/userinfo',
    })
  } else {
    res = await makeUMRequest({
      method: 'GET',
      url: '/auth/userinfo',
    })
  }

  const userInfo = res.data

  localStorage.setItem('csrfToken', userInfo.csrfToken)
  if (userInfo.activeOrganization.idpUrl) {
    localStorage.setItem('idpUrl', userInfo.activeOrganization.idpUrl)
  }
  const orgId = userInfo.activeOrganization.id
  const { data: tokenResponse }: AxiosResponse = await makeUMRequest({
    method: 'POST',
    url: '/auth/token',
    data: { orgId, productName: 'Reactor' },
  })
  localStorage.setItem('UMToken', tokenResponse.token)
  return { userInfo, token: tokenResponse }
}

const refreshOIDCAccessToken = async (refreshToken: string | null) => {
  const { data: tokenResponse }: AxiosResponse = await makeUMRequest({
    method: 'POST',
    url: '/auth/token/refreshoidctoken',
    params: {
      refresh_token: refreshToken,
    },
  })
  localStorage.setItem('accessToken', tokenResponse.accessToken)
  localStorage.setItem('refreshToken', tokenResponse.refreshToken)
  return tokenResponse
}

const clearUserSessionInfoStorage = () => {
  localStorage.removeItem('isIDPLogin')
  localStorage.removeItem('isIDPLoginViaSAML')
  localStorage.removeItem('idpUrl')
  localStorage.removeItem('csrfToken')
  localStorage.removeItem('UMToken')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

const logout = () => {
  clearUserSessionInfoStorage()
  window.location.href = process.env.REACT_APP_LOGIN_URL || '/login'
}

const apiResponseErrorInterceptor = async (error: any): Promise<any> => {
  if (
    error.response &&
    error.response.status === 401 &&
    !window.location.pathname.includes('login') &&
    !window.location.pathname.includes('password')
  ) {
    logout()
  } else if (
    (error.response && error.response.status === 403) ||
    (error.response &&
      error.response.data.messages &&
      error.response.data.messages[0].token_class === 'AccessToken')
  ) {
    const originalRequest = error.config
    const originalReqAccessToken = parseBearerToken(originalRequest)
    const originalReqUMToken = parseUMToken(originalRequest)

    // if failed request doesn't have OIDC access token or UM token in header
    if (!originalReqAccessToken && !originalReqUMToken) {
      return Promise.reject(error)
    }

    // do not replay request if it has already been replayed equal to the `MAX_REQUEST_RETRIES`
    originalRequest.retry_count =
      typeof originalRequest.retry_count === 'undefined'
        ? 0
        : ++originalRequest.retry_count
    if (originalRequest.retry_count === MAX_REQUEST_RETRIES) {
      return Promise.reject(error)
    }

    // req failed with the OIDC access token (JWT)
    if (originalReqAccessToken) {
      // make an refresh OIDC access token API call if it is not already in progress
      if (!accessTokenRenewalInProgressSubject.value) {
        accessTokenRenewalInProgressSubject.next(true)
        const oidcRefreshToken = localStorage.getItem('refreshToken')
        return refreshOIDCAccessToken(oidcRefreshToken)
          .then(() => {
            return getUMToken()
          })
          .then(() => {
            accessTokenRenewalInProgressSubject.next(false)
            // NOTE: since APIs are not fully OIDC jwt compliant user management token is also passed
            originalRequest.headers.Token = localStorage.getItem('UMToken')
            // OIDC Access Token is only available in case of OIDC login
            originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
              'accessToken'
            )}`
            return umClient.request(originalRequest)
          })
          .catch(async () => {
            accessTokenRenewalInProgressSubject.next(false)
            await logout()
          })
      }
      // wait until new access token is fetched and then replay all the failed requests with a new OIDC access token
      return accessTokenRenewalInProgressSubject
        .pipe(
          filter(renewalInProgress => !renewalInProgress),
          take(1),
          switchMap(() => {
            // NOTE: since APIs are not fully OIDC jwt compliant user management token is also passed
            originalRequest.headers.Token = localStorage.getItem('UMToken')
            // OIDC Access Token is only available in case of OIDC login
            originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
              'accessToken'
            )}`
            return umClient.request(originalRequest)
          })
        )
        .toPromise()
    }

    // make an API call to get a new UM token if it is not already in progress
    if (!UMTokenRenewalInProgressSubject.value) {
      UMTokenRenewalInProgressSubject.next(true)
      return getUMToken()
        .then(() => {
          UMTokenRenewalInProgressSubject.next(false)
          originalRequest.headers.Token = localStorage.getItem('UMToken')
          return umClient.request(originalRequest)
        })
        .catch(async () => {
          UMTokenRenewalInProgressSubject.next(false)
          logout()
        })
    }
    // wait until new UM token is fetched and then replay all the failed requests with a new UM token
    return UMTokenRenewalInProgressSubject.pipe(
      filter(renewalInProgress => !renewalInProgress),
      take(1),
      switchMap(() => {
        originalRequest.headers.Token = localStorage.getItem('UMToken')
        return umClient.request(originalRequest)
      })
    ).toPromise()
  }
  return Promise.reject(error)
}

umClient.interceptors.response.use(
  async response => response,
  apiResponseErrorInterceptor
)

export { makeUMRequest }
