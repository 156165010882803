import { AxiosResponse } from 'axios'
import { makeUMRequest } from '../utils/request'
import { IAssignMultipleUsersToLicenseRequest } from '../utils/types'

class LicenseAPI {
  static async getAllLicenses(orgId: number | null, params: any): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/licenses`,
      params,
    })
    return res.data
  }

  static async getLicense(orgId: number, licenseId: number): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/license/${licenseId}`,
    })
    return res.data
  }

  static async assignOrgMemberToLicense(
    licenseId: number,
    userId: number
  ): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'PUT',
      url: `orgadmin/license/${licenseId}/user/${userId}`,
    })
    return res.data
  }

  static async assignOrgMembersToLicense(
    licenseId: number,
    data: IAssignMultipleUsersToLicenseRequest
  ): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'PUT',
      url: `orgadmin/license/${licenseId}/users`,
      data,
    })
    return res.data
  }
}

export default LicenseAPI
