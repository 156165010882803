const parseLocalStorageItem = (item: string): any => {
  try {
    const str = localStorage.getItem(item)
    return str ? JSON.parse(str) : null
  } catch (err) {
    return null
  }
}

export const getPluralizedString = (
  length: number,
  singular: string,
  plural: string
) => `${length} ${length === 1 ? singular : plural}`

export default parseLocalStorageItem
