import { Layout, Divider, Typography, Tooltip, Space, Select } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import logo from '../img/logo.svg'
import { useAuth, useNavigation } from './hooks'
import Authentication from '../utils/authentication'
import { makeUMRequest } from '../utils/request'

const { Header } = Layout
const { Title } = Typography

const Nav = () => {
  const { loggedInUser, orgSwitcherFeatureEnabled } = useAuth()
  const { toOrganization } = useNavigation()
  const hasMultipleOrgs = loggedInUser?.organizations?.length > 1

  async function switchOrg(orgMemberId: number) {
    const data = {
      orgMemberId: orgMemberId,
    }

    const response = await makeUMRequest({
      method: 'PUT',
      url: '/profile/organization/active',
      data,
    })

    window.location.reload()
    window.location.href = '/'
  }

  return (
    <Header
      css={{
        backgroundColor: '#fff',
        border: 'solid rgba(34,36,38,.15)',
        borderWidth: '0 0 1px',
      }}
    >
      <section
        role="button"
        tabIndex={-1}
        className="logo"
        style={{ display: 'inline-block', cursor: 'pointer' }}
        onClick={() => toOrganization()}
      >
        <Space>
          <img src={logo} css={{ height: '32px' }} alt="Chainalysis" />
          <Title
            level={3}
            style={{ verticalAlign: 'middle', display: 'inline' }}
          >
            Admin Portal
          </Title>
        </Space>
      </section>
      <section style={{ display: 'inline-block', float: 'right' }}>
        {loggedInUser ? (
          <>
            {hasMultipleOrgs && orgSwitcherFeatureEnabled && (
              <Select
                showSearch
                style={{
                  width: '300px',
                  marginRight: '16px',
                  backgroundColor: 'red',
                }}
                placeholder="Choose a license"
                onChange={(id: number) => {
                  switchOrg(id)
                }}
                value={
                  loggedInUser?.activeOrganization?.orgName || 'Unavailable'
                }
                filterOption={(input, option) =>
                  String(option?.label)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionLabelProp="label"
              >
                {loggedInUser.organizations.map((org: any) => (
                  <Select.Option
                    key={org.id}
                    value={org.orgMemberId}
                    label={org.orgName}
                  >
                    {org.orgName}
                  </Select.Option>
                ))}
              </Select>
            )}

            <Tooltip
              title={
                <div>
                  <section>Full name: {loggedInUser.fullName}</section>
                  <section>User name: {loggedInUser.userName}</section>
                </div>
              }
            >
              <Typography.Text>{`Welcome ${loggedInUser.userName}`}</Typography.Text>
            </Tooltip>
            <Divider type="vertical" />
          </>
        ) : null}
        <span
          role="button"
          tabIndex={-2}
          onClick={() => Authentication.logout()}
          style={{ cursor: 'pointer' }}
        >
          <LogoutOutlined /> Sign out
        </span>
      </section>
    </Header>
  )
}

export default Nav
