import { Result, Button } from 'antd'
import { useNavigation } from './hooks'

const PageNotFound = () => {
  const { toOrganization } = useNavigation()
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => toOrganization()}>
          Back Home
        </Button>
      }
    />
  )
}

export default PageNotFound
