import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import Loadable from 'react-loadable'
import PrivateRoute from './PrivateRoute'
import Login from './login/Login'
import Loader from './Loader'
import PageNotFound from './PageNotFound'
import { ProvideAuth } from './hooks/check-auth'
import AppContainer from './AppContainer'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import './App.css'

const Loading = () => <Loader container />

const LoadableOrganization = Loadable({
  loader: () => import('./Organization'),
  loading: Loading,
})

const LoadableOrganizationConnections = Loadable({
  loader: () => import('./OrganizationConnections'),
  loading: Loading,
})

const LoadableOrganizationOIDCConnection = Loadable({
  loader: () => import('./OrganizationOIDCConnection'),
  loading: Loading,
})

const LoadableOrganizationSAMLConnection = Loadable({
  loader: () => import('./OrganizationSAMLConnection'),
  loading: Loading,
})

const LoadableLisenseView = Loadable({
  loader: () => import('./LicenseDetails'),
  loading: Loading,
})

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <Switch>
            <Route
              exact
              path="/"
              render={routeProps => <Redirect to="/organization" />}
            />
            <Route
              path="/login"
              render={routeProps => <Login {...routeProps} />}
            />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/password-reset" component={ResetPassword} />
            <AppContainer>
              <Switch>
                <PrivateRoute
                  exact
                  path="/organization"
                  component={LoadableOrganization}
                />
                <PrivateRoute
                  exact
                  path="/organization/:organizationId([0-9]+)/sso"
                  component={LoadableOrganizationConnections}
                />
                <PrivateRoute
                  exact
                  path="/organization/:organizationId([0-9]+)/sso/oidc"
                  component={LoadableOrganizationOIDCConnection}
                />
                <PrivateRoute
                  exact
                  path="/organization/:organizationId([0-9]+)/sso/saml"
                  component={LoadableOrganizationSAMLConnection}
                />
                <PrivateRoute
                  exact
                  path="/organization/:organizationId([0-9]+)/license/:licenseId([0-9]+)"
                  component={LoadableLisenseView}
                />
                <PrivateRoute component={PageNotFound} />
              </Switch>
            </AppContainer>
          </Switch>
        </Router>
      </ProvideAuth>
    </QueryClientProvider>
  )
}

export default App
